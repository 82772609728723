import { set } from "date-fns"

interface appointmentFile {
    file_pos: number,
    file_id: number,
    appointment_id: number,
    file_name: string,
    file_description: string,
    file_type: string,
    file_size: number,
    file_path: string
    upload_time: string
    file_content: Blob,
    file_url: string,
    download_url: string,
    file: File
}

interface Appointment {
    appointment_id: number,
    customer_user_id: number,
    customer_name: string,
    pet_id: number,
    pet_name: string,
    vet_user_id: number,
    vet_name: string,
    description: string,
    schedule: string,
    customer_pin: number,
    vet_pin: number,
    appointment_status: string,
    status_date: string,
    vet_observations: string,
    files: appointmentFile[]
}

interface ConfirmPaymentParams {
    payment_hash: string,
    language: string
}

interface Pet {
    pet_id: number,
    user_id: number,
    name: string
    species: string,
    breed: string,
    gender: string,
    birth: Date,
    fertile_status: string,
    weight: number,
}


const appointmentsResetState = () => ({
    appointmentData: {
        hash: '',
        email: '',
        name: ''
    },
    editedAppointmentData:
    {
        id: 0,
        hash: '',
        customerUserId: 0,
        customerName: '',
        petId: 0,
        petName: '',
        vetUserId: 0,
        vetName: '',
        description: '',
        schedule: '0000-00-00 00:00:00',
        customerPin: '',
        vetPin: '',
        appointmentStatus: '',
        statusDate: '0000-00-00 00:00:00',
        vetObservations: '',
    },
    appointmentFiles: [] as appointmentFile[],
    newAppointmentFileData:
    {
        file_pos: 0,
        file_id: 0,
        appointment_id: 0,
        file_name: '',
        file_description: '',
        file_type: '',
        file_size: '',
        file_path: '',
        file_content: new Blob([], { type: 'application/octet-stream' }),
        file_url: "",
        download_url: "",
        file: null
    },
    editingAppointment: false,
    appointmentFileIdsToBeDeleted: [] as number[],
    showingAppointmentData: false,
    showingHistoricalAppointmentData: false
});

export const appointment = {
    state: appointmentsResetState(),
    mutations: {
        appointmentHash(state: any, appointmentHash: string) {
            state.appointmentData.hash = appointmentHash
        },
        appointmentEmail(state: any, appointmentEmail: string) {
            state.appointmentData.email = appointmentEmail
        },
        appointmentName(state: any, appointmentName: string) {
            state.appointmentData.name = appointmentName
        },
        setEditedAppointmentData(state: any, editedAppointmentdata: any) {
            state.editedAppointmentData = editedAppointmentdata
        },
        /* Previous version with base64 encoded files
        setEditedAppointmentFilesData(state: any, editedAppointmentFilesdata: any) {
            console.log("setEditedAppointmentFilesData");
            console.log(editedAppointmentFilesdata);

            state.appointmentFiles = [];
            editedAppointmentFilesdata.forEach((file: any, index: number) => {
                file.file_pos = index;
                // Decode base64 content back to binary data
                const binaryData = atob(file.file_content);
                // Convert binary data to a Blob
                const byteArray = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    byteArray[i] = binaryData.charCodeAt(i);
                }
                file.file_content = new Blob([byteArray], { type: 'application/octet-stream' });
                file.file_url = URL.createObjectURL(file.file_content)
                console.log(file);
                state.appointmentFiles.push(file)
            });
        },
        */

        /* New version with direct download URLs */
        setEditedAppointmentFilesData(state: any, editedAppointmentFilesData: any) {
            console.log("setEditedAppointmentFilesData");
            console.log(editedAppointmentFilesData);

            state.appointmentFiles = [];

            // Process each file
            editedAppointmentFilesData.forEach((file: any, index: number) => {
                file.file_pos = index;
                console.log(file);
                // Ensure download_url is set
                file.download_url = file.download_url || `/api/appointments/download-file.php?file_id=${file.file_id}`;
                state.appointmentFiles.push(file);
            });
            
        },

        setEditedAppointmentAddFile(state: any, newFile: any) {
            newFile.file_pos = state.appointmentFiles.length
            state.appointmentFiles.push(newFile)
        },

        setEditedAppointmentRemoveFile(state: any, file_pos: number) {
            console.log("setEditedAppointmentRemoveFile")
            state.appointmentFiles.splice(file_pos, 1)
            state.appointmentFiles.forEach((file: any, index: number) => {
                file.file_pos = index;
            })
        },

        setEditedAppointmentIniFile(state: any) {
            state.newAppointmentFileData = {
                file_pos: 0,
                file_id: 0,
                appointment_id: 0,
                file_name: '',
                file_description: '',
                file_type: '',
                file_size: '',
                file_path: '',
                upload_time : '',
                file: null
            }
        },

        setEditedAppointmentFileNewData(state: any, newFile: File) {
            state.newAppointmentFileData.file_name = newFile.name
            state.newAppointmentFileData.file_type = newFile.type
            state.newAppointmentFileData.file_size = newFile.size
            state.newAppointmentFileData.file = newFile
        },

        setEditingAppointment(state: any, editingAppointment: boolean) {
            state.editingAppointment = editingAppointment
        },

        setEditedAppointmentFilesToDelete(state: any, FileIdsToDelete: number[]) {
            state.appointmentFileIdsToBeDeleted = FileIdsToDelete
        },

        setEditedAppointmentAddFileIdToDelete(state: any, fileId: number) {
            state.appointmentFileIdsToBeDeleted.push(fileId)
        },
        setShowingAppointmentData(state: any, showingAppointmentData: boolean) {
            state.showingAppointmentData = showingAppointmentData
        },
        setShowingHistoricalAppointmentData(state: any, showingHistoricalAppointmentData: boolean) {
            state.showingHistoricalAppointmentData = showingHistoricalAppointmentData
        },
        appointmentResetStateMut(state: any) {
            Object.assign(state, appointmentsResetState())
        }
    },
    getters: {
        appointmentHash(state: any) {
            return state.appointmentData.hash
        },
        appointmentEmail(state: any) {
            return state.appointmentData.email
        },
        appointmentName(state: any) {
            return state.appointmentData.name
        },
        getEditedAppointmentData(state: any): Appointment {
            return (state.editedAppointmentData)
        },
        getEditedAppointmentFiles(state: any): appointmentFile[] {
            return (state.appointmentFiles)
        },
        getEditedAppointmentNewFileData(state: any): appointmentFile {
            return (state.newAppointmentFileData)
        },
        getEditingAppointment(state: any): boolean {
            return (state.editingAppointment)
        },
        getEditedAppointmentFilesToDelete(state: any): number[] {
            return (state.appointmentFileIdsToBeDeleted)
        },
        getShowingAppointmentData(state: any): boolean {
            return (state.showingAppointmentData)
        },
        getShowingHistoricalAppointmentData(state: any): boolean {
            return (state.showingHistoricalAppointmentData)
        }
    },
    actions: {
        checkAppointment(state: any, appointmentHash: string) {
            return new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/appointments/info/${appointmentHash}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`,
                    }
                }).then(res => {
                    if (res.status === 200) {
                        // valid appointment
                        res.json().then(response => {
                            resolve(response);
                        })
                    } else {
                        // invalid appointment
                        reject();
                    }
                })
            })
        },
        confirmAppointment(state: any, data: any) {
            return new Promise<void>((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/appointments/confirm/${data.hash}`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`,
                    },
                    body: JSON.stringify(data)
                }).then(res => {
                    if (res.status === 200) {
                        res.json().then(data => {
                            resolve(data)
                        })
                    } else {
                        reject()
                    }
                })
            })
        },
        
        getAppointmentByPIN(context: any, pin: string) {
            return new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/appointments/pin/${pin}`, {
                    method: 'GET'
                }).then(res => {
                    if (res.status === 200) {
                        // valid appointment
                        res.json().then(response => {
                            resolve(response);
                        })
                    } else {
                        // invalid appointment
                        reject();
                    }
                })
            })
        },

        getAppointmentByPaymentHash(state: any, paymentHash: string) {
            return new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/appointments/paymenthash/${paymentHash}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${state.getters.token}`,
                        'Content-Type': 'application/json'  // Optional, depending on your API's requirements
                    }
                }).then(res => {
                    if (res.status === 200) {
                        // valid appointment
                        res.json().then(response => {
                            resolve(response);
                        })
                    } else {
                        // invalid appointment
                        reject();
                    }
                })
            })
        },

        async requestAppointment(state: any, appointmentFormData: any) {
            // Log the content of appointmentFormData
            console.log('requestAppointment');
            appointmentFormData.forEach((value: any, key: any) => {
                if (value instanceof File) {
                    console.log(`${key}: [File] ${value.name}`);
                } else {
                    console.log(`${key}: ${value}`);
                }
            });
        
            try {
                const response = await fetch(process.env.BASE_URL + `api/appointments`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`,
                    },
                    body: appointmentFormData
                });
        
                if (response.status === 200) {
                    const res = await response.json();
                    return res;
                } else {
                    throw new Error('Failed to request appointment');
                }
            } catch (error) {
                throw error;
            }
        },

        async confirmPayment(state: any, confirmPaymentParams: ConfirmPaymentParams) {
            const response = await fetch(process.env.BASE_URL + `api/appointments/confirmpayment/${confirmPaymentParams.payment_hash}/${confirmPaymentParams.language}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
                });
            if (response.ok) {
                return true;
            } else {
                throw new Error('invalid_payment_hash')
            }
        },

        addFileEditedAppointment(state: any, newFile: appointmentFile) {

            newFile.file_id = 0
            console.log(newFile)
            state.commit('setEditedAppointmentAddFile', newFile)

        },

        saveFileToBeDeleted(state: any, fileId: number) {
            console.log(fileId)
            state.commit('setEditedAppointmentAddFileIdToDelete', fileId)
        },

        removeFileEditedAppointment(state: any, file_pos: number) {
            console.log(file_pos)
            state.commit('setEditedAppointmentRemoveFile', file_pos)
        },

        iniEditedAppointmentNewFile(state: any) {
            state.commit('setEditedAppointmentIniFile')
        },

        updateNewFileData(state: any, selectedFile: File)
        {
            state.commit('setEditedAppointmentFileNewData', selectedFile)
            
        },

        async iniEditedAppointment(state: any, appointmentId: number) {

            console.log("iniEditedAppointment")
            
            return (new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/appointments/byid/` + appointmentId,
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    })
                    .then((res) => res.json())
                    .then((appointment_data) => {
                        console.log(appointment_data);
                        console.log(appointment_data.appointment_id);
                        state.commit('setEditedAppointmentData', appointment_data)
                        resolve(appointment_data)
                    })
                    .catch(err => reject(err))
            }))

        },
        async iniEditedAppointmentFiles(state: any, appointmentId: number) {

            console.log("iniEditedAppointmentFiles");
            console.log(appointmentId);
            console.log(process.env.BASE_URL + `api/appointments/files/` + appointmentId.toString());
            

            return (new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/appointments/files/` + appointmentId.toString(),
                    {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    })
                    .then(async (res) => {
                        if (!res.ok) {
                            throw new Error(`HTTP error! Status: ${res.status}`);
                        }
                        return res.json();
                    })
                    .then((appointmentFilesData) => {
                        console.log("Received appointment files:", appointmentFilesData);
                        
                        // Store the data in Vuex
                        state.commit('setEditedAppointmentFilesData', appointmentFilesData);
                        resolve(appointmentFilesData);
                    })
                    .catch(err => {
                        console.error("Error fetching appointment files:", err);
                        reject(err);
                    });
            }))

        },

        /**
         * Download a file with the authorization token
         * @param {Object} state Vuex state
         * @param {Object} params { fileUrl, fileName }
         */
        async downloadFile( state : any, { fileUrl, fileName }: { fileUrl: string, fileName: string }) {
            
            console.log("downloadFile");

            if (!state.getters.token) {
                console.error("Token is not available");
                return; // Exit the function if the token is not available
            }
            
            console.log(process.env.BASE_URL);

            console.log(process.env.BASE_URL + fileUrl);


            try {
            const response = await fetch(process.env.BASE_URL + fileUrl,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${state.getters.token}`,
                    }
                });
    
            if (!response.ok) {
                throw new Error('Failed to download file');
            }
    
            // Convert the response to a Blob (binary data)
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName; // Set the file name for download
            link.click(); // Trigger the download
            } catch (error) {
            console.error('Error downloading file:', error);
            // You can also handle error notifications here
            }
        },

        iniAppointmentEditing(state: any) {

            state.commit('setEditingAppointment', true);
            state.commit('setEditedAppointmentFilesToDelete', [])
        },

        endAppointmentEditing(state: any) {

            state.commit('setEditingAppointment', false);
            state.commit('setEditedAppointmentFilesToDelete', [])
        },

        async editAppointment(state: any, editedAppointmentData: Appointment) {
            console.log(JSON.stringify(editedAppointmentData))
            const response = await fetch(process.env.BASE_URL + `api/appointments/` + editedAppointmentData.appointment_id.toString(), {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                },
                body: JSON.stringify(editedAppointmentData),
            })

            var editedAppointmentId: number = 0

            if (response.ok) {
                const resjson = await response.json()
                editedAppointmentId = resjson.user_id

            } else {
                throw new Error('error_updating_appointment')
            }
            return (editedAppointmentId)
        },

        async notifyOwnerNewFileAdded(state: any, {editedAppointmentData, language}: {editedAppointmentData: Appointment, language: string})
        {
            console.log(JSON.stringify(editedAppointmentData))
            const response = await fetch(process.env.BASE_URL + `api/appointments/newfileadded/${editedAppointmentData.appointment_id.toString()}/${language}`, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                },
                body: JSON.stringify(editedAppointmentData),
            })

            var editedAppointmentId: number = 0

            if (response.ok) {
                const resjson = await response.json()
                editedAppointmentId = resjson.user_id

            } else {
                throw new Error('error_notifying_petowner')
            }
            return (editedAppointmentId)
        },

        async createEditedAppointmentFile(state: any, newFileData: any) {
            const appointmentId = JSON.parse(newFileData.get('file_data')).appointment_id
            console.log(JSON.stringify(newFileData))
            const response = await fetch(process.env.BASE_URL + `api/appointments/file/` + appointmentId.toString(), {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                },
                body: newFileData
            })
            if (!response.ok) {
                throw new Error('error_creating_new_file')
            }
        },

        async deleteEditedAppointmentFile(state: any, fileId: number) {
            console.log(fileId)
            const response = await fetch(process.env.BASE_URL + `api/appointments/file/` + fileId.toString(), {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${state.getters.token}`,
                }
            })
            if (!response.ok) {
                throw new Error('error_deleting_file')
            }
        },

        toggleShowingAppointmentData(state: any, showingAppointmentData: boolean) {
            console.log("showingAppointmentData")
            state.commit('setShowingAppointmentData', !showingAppointmentData)
        },

        toggleShowingHistoricalAppointmentData(state: any, showingHistoricalAppointmentData: boolean) {
            console.log("HistoricalAppointmentData")
            state.commit('setShowingHistoricalAppointmentData', !showingHistoricalAppointmentData)
        },

        resetAppointmentFiles(state: any) {
            state.commit('setEditedAppointmentFilesData', [])
        }
    }
}
