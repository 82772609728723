<template>
  <td class = "sticky-column">
    <a @click.prevent="downloadFile(fileData.download_url, fileData.file_name)" target="_blank">
    {{ fileData.file_name }}
  </a>
  </td>
  <td>
    <div>{{ fileData.file_description }}</div>
  </td>
  <td>
    <div>{{ fileData.file_type }}</div>
  </td>
  <td>
    <div>{{ formatFileSize(fileData.file_size) }}</div>
  </td>
  <td>
    <div>{{ fileData.upload_time }}</div>
  </td>
  <td>
    <div class="is-flex is-justify-content-start" style="gap: 1em;">
      <div v-if="editingAppointment()">
        <button type="button" class="button is-danger is-small" @click="remove(fileData, $t)">Delete</button>
      </div>
    </div>
  </td>
</template>


<script lang="ts">
import { defineComponent, defineProps, onMounted } from 'vue'
import { useStore } from "vuex";
import { Notyf } from "notyf";

const props = defineProps(['fileData'])
const store = useStore()

const notyf = new Notyf()

export default defineComponent({
  name: "AppointmentDataFileTableRow",
})

</script>
<script lang="ts" setup>

const props = defineProps(['fileData'])
const store = useStore()

const downloadFile = async (fileUrl: string, fileName: string) => {
  // Dispatch the action to the Vuex store for downloading the file
  await store.dispatch('downloadFile', { fileUrl, fileName });
};


function editingAppointment(): boolean {
  console.log(store.getters.getEditingAppointment)
  return (store.getters.getEditingAppointment)
}

const remove = async (fileData: any, $t: any) => {
  if (confirm('Do you want to remove this file?')) {
    //TODO:Create notification to ask if it is really to delete
    console.log("remove")
    console.log(store.getters.getEditedAppointmentFiles)
    if (fileData.file_id != 0) {
      // Store appointment to be deleted in the DB when saving data.
      // Only of it hasn't been added in this interaction
      await store.dispatch('saveFileToBeDeleted', fileData.file_id)
    }
    try {
      // Remove file from the list front end
      await store.dispatch('removeFileEditedAppointment', fileData.file_pos)
      console.log(store.getters.getEditedAppointmentFiles)
      notyf.success($t('FilesData.NotyfFileRemoved'))
    } catch (e) {
      notyf.error($t('FilesData.NotyfErrorRemoveFile'))
    }
  }
}

function formatFileSize(size: number) {
  if (size < 1024) return `${size} B`;
  if (size < 1048576) return `${(size / 1024).toFixed(1)} KB`;
  return `${(size / 1048576).toFixed(1)} MB`;
}

onMounted(() => {
  console.log("Mounted AppointmentDataFileTableRow")

})

</script>
<style scoped>
.pointer {
  cursor: pointer;
}
.sticky-column {
  position: sticky;
  max-width: 150px; /* Ensure it doesn't expand beyond this width */
  left: 0;
  background: #fff; /* Match table background */
  z-index: 2;
  border-right: 1px solid #ddd; 
  border-left: 1px solid #ddd;
  will-change: left; /* Optimize performance */
}
</style>
